import React, { useState } from 'react';

const Form = ({ addData }) => {
  const [animalData, setAnimalData] = useState('');
  const [apparentAgeData, setApparentAgeData] = useState('');
  const [physicalConditionData, setPhysicalConditionData] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addData({
      animal: animalData,
      apparentAge: apparentAgeData,
      physicalCondition: physicalConditionData
    });
    setAnimalData('mensagem formada!');
  };

  const handleAnimal = (e) => setAnimalData(e.currentTarget.value);
  const handleApparentAge = (e) => setApparentAgeData(e.currentTarget.value);
  const handlePhysicalCondition = (e) => setPhysicalConditionData(e.currentTarget.value);

  return (
    <div className="sosanimal-form">
    <form onSubmit={handleSubmit}>
      <label htmlFor="animal">Animal encontrado:&nbsp;</label>
      <input type="text" className="form-control form-control-sm animal-form" id="animal" name="animal" placeholder="ex: gato" value={animalData} onChange={handleAnimal} required/>
      <label htmlFor="idade">Idade aparente:&nbsp;</label>
      <select id="idade" className="form-control form-control-sm animal-form" name="idade" value={apparentAgeData} onChange={handleApparentAge} required>
        <option></option>
        <option value="recém-nascido(a)">Recém-nascido(a)</option>
        <option value="filhote">Filhote</option>
        <option value="adulto(a)">Adulto(a)</option>
        <option value="idoso(a)">Idoso(a)</option>
      </select>
      <label htmlFor="estado">Condição física:&nbsp;</label>
      <select id="estado" className="form-control form-control-sm animal-form" name="estado" value={physicalConditionData} onChange={handlePhysicalCondition} required>
        <option></option>
        <option value="saudável">Saudável</option>
        <option value="ferido(a)">Ferido(a)</option>
        <option value="debilitado(a)">Debilitado(a)</option>
      </select>
      <button type="submit" className="btn btn-info animal-button">Definir animal</button>
    </form>
  </div>
  );
};

export default Form;
