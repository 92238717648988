import React from 'react';
import { GoogleMap } from '@react-google-maps/api';

const RenderedGoogleMap = ({ coords, mapContainerStyle, center }) => {
  return (
    <div className="rendered-map">
      { 
        (coords.latitude && coords.longitude) ?
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={19} center={center} />
        : null
      }
    </div>
  );
};

export default RenderedGoogleMap;
