import React from 'react';

const GithubRedirector = () => {
  return (
    <div className="github-redirector">
      <a href="https://github.com/lucas-deschamps" target="_blank" rel="noopener noreferrer">
        <img src="https://icons-for-free.com/iconfiles/png/512/part+1+github-1320568339880199515.png" alt="github-profile" title="my github profile"/>
      </a>
    </div>
  );
};

export default GithubRedirector;
