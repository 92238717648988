import React, { useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import './css/app.css';

// components
import Form from './components/Form';
import Address from './components/Address';
import SOSAnimalMessage from './components/SOSAnimalMessage';
import TweetButton from './components/TweetButton';
import AppHeader from './components/AppHeader';
import RenderedGoogleMap from './components/RenderedGoogleMap';
import GithubRedirector from './components/GithubRedirector';

function App() {
  const [coordinates, setCoords] = useState({});
  const [address, setAddress] = useState('');
  const [objData, setObjData] = useState({});

  const handleNavigatorError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Usuário não permitiu acesso à geolocalização.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Informação de localidade indisponível.");
        break;
      case error.TIMEOUT:
        alert("A requisição para obter a informação do usuário esgotou o tempo limite.");
        break;
      case error.UNKNOWN_ERROR:
        alert("Um erro desconhecido aconteceu.");
        break;
      default:
        alert("Um erro desconhecido aconteceu.");
        break;
    };
  };

  const setLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&key=AIzaSyDzaJ4QDKyxOPp3DBXKDh42xrjGVdPJwFs`)
          .then(res => res.json())
          .then(data => setAddress(data.results[0].formatted_address))
          .catch(err => console.log(err));
        setCoords((prev) => ({
            ...prev,
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
        }));
      }, handleNavigatorError, { enableHighAccuracy: true, maximumAge: 0 });
    } else {
      alert("Seu navegador é incompatível com geolocalização.");
    };
  };

  // empty array as second argument so you only ask for the user's location once, rather than on every re-render
  useEffect(setLocation, []);

  // could simply use property shorthand syntax here (i.e animal, apparentAge, physicalCondition) after destructuring the form data
  // however, I think the logic reads clearer this way
  const packObject = (formData) => {
    setObjData({
      address,
      coordinates,
      animalData: {
        animal: formData.animal,
        apparentAge: formData.apparentAge,
        physicalCondition: formData.physicalCondition
      }
    });
  };

  // setup for dynamic Google Map
  const { loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDzaJ4QDKyxOPp3DBXKDh42xrjGVdPJwFs",
  });

  const mapContainerStyle = {
    width: "75vw",
    height: "75vh"
  };

  const center = {
    lat: coordinates.latitude,
    lng: coordinates.longitude
  };

  if (loadError) return (<div className="load-error">Erro ao carregar o mapa. :( <br/> Por favor, verifique sua conexão e tente novamente.</div>);

  return (
    <div className="main">
      <AppHeader />
      <Form addData={packObject} />
      <Address locationAddress={address} />
      <SOSAnimalMessage objectData={objData} locationAddress={address} coordinates={coordinates} />
      <TweetButton objectData={objData} locationAddress={address} coordinates={coordinates} />
      <RenderedGoogleMap coords={coordinates} mapContainerStyle={mapContainerStyle} center={center} />
      <GithubRedirector />
    </div>
  );
}

export default App;
