import React from 'react';

const TweetButton = ({ objectData, locationAddress, coordinates }) => {
  
  const titleCaseAnimal = (animalString) => {
    return animalString.charAt(0).toUpperCase() + animalString.slice(1);
  };

  const removePhoneWhitespace = (str) => str.replace(/\s+$/, '');

  return (
    <div className="tweet-button">
      {
        (objectData.animalData) ?
          (<a className="twitter-share-button"
          href={`https://mobile.twitter.com/intent/tweet?text=${titleCaseAnimal(removePhoneWhitespace(objectData.animalData.animal))} ${objectData.animalData.apparentAge} ${objectData.animalData.physicalCondition} encontrado por volta de ${locationAddress}; coordenadas ${coordinates.latitude}, ${coordinates.longitude} (latitude, longitude).&hashtags=SOSAnimalBR`}>
            <button className="btn btn-info btn-lg">Enviar Tweet</button>
          </a>)
          : 
          null
      }
    </div>
  );
};

export default TweetButton;
